import React, {useState} from 'react';
import style from './DoingsTaskListLine.module.css';
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";

export const DoingsTaskListAddLine = ({taskActions}) => {
    const [name, setName] = useState("")

    return <div className={style.simpleWrapper}>

        <ButtonComp
            icon={ICONS.plus}
            tooltipText={"Create new task"}
            onClick={() => {
                taskActions.createTask(name)
                setName("")
            }}
        />

        <InputTextComp
            title={"New task name"}
            defaultText={name}
            acceptChanges={setName}
        />

    </div>
}
