import {parentElementIs} from "../../../../lib/TextHtmlHelper";

export function getTableUnderCaret() {
    // check current cursor in TD -> TR -> TBODY -> TABLE
    let rangeCur = document.getSelection()

    if (rangeCur.getRangeAt(0).startContainer !== rangeCur.getRangeAt(0).endContainer) return

    let tableCell = parentElementIs(rangeCur.focusNode, "TD")

    let tableRow = parentElementIs(tableCell ? tableCell: rangeCur.focusNode, "TR")
    if (!tableRow) return

    let tableBody = parentElementIs(tableRow, "TBODY")

    let table = parentElementIs(!!tableBody ? tableBody : tableRow, "TABLE")
    if (!table) return

    let rowsArray = tableBody ? tableBody.children : table.children

    // table params
    let columnCount = tableRow.children.length
    let rowCount = !!tableBody ? tableBody.children.length : table.children.length

    // current indexes
    let currentColIndex = Array.from(tableRow.children).indexOf(tableCell)
    let currentRowIndex = Array.from(rowsArray).indexOf(tableRow)


    return {
        table: table,
        rowsArray: rowsArray,
        currentRow: tableRow,
        currentCell: tableCell,
        currentRowIndex: currentRowIndex,
        currentColIndex: currentColIndex,
        columnCount: columnCount,
        rowCount: rowCount,
    }
}



export const createTableColumn = (refWorkSpace, insertPlace) => {
    let tbl = getTableUnderCaret()
    if (!tbl) return

    // Use parentElementIs to find the TABLE element
    let tableElement = parentElementIs(tbl.table, "TABLE", refWorkSpace);
    if (!tableElement) return;

    // find cell position in row
    let insertIndex = tbl.currentColIndex + (insertPlace === "before" ? 0 : 1)

    for (let i = tbl.rowsArray.length - 1; i >= 0; i--) {
        tbl.rowsArray[i].insertCell(insertIndex)
    }
    refWorkSpace.current.focus()
}

export const createTableRow = (refWorkSpace, insertPlace) => {
    let tbl = getTableUnderCaret()
    if (!tbl) return

    // Use parentElementIs to find the TABLE element
    let tableElement = parentElementIs(tbl.table, "TABLE", refWorkSpace);
    if (!tableElement) return;

    // create new TABLE ROW
    let newRow = tableElement.insertRow(tbl.currentRowIndex + (insertPlace === "before" ? 0 : 1))

    // fill row
    let newCell = null
    for (let i = 0; i < tbl.columnCount; i++) {
        newCell = newRow.insertCell(0)
        newCell.innerHTML = "<br>"
    }

    refWorkSpace.current.focus()
}

