import style from "../CookingReceiptDetails/CookingNormalMode.module.css"
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {SelectComp} from "../../../../../shared/ui/Select/SelectComp";
import {useState} from "react";
import {useCooking} from "../../../lib/hooks/UseCooking";

export const CookingEditModeResult = ({receipt}) => {
    const {updateReceipt, dictionaryUnit} = useCooking()

    const [selectedUnit, setSelectedUnit] = useState(receipt.resultUnit)

    return <div className={style.receipt}>
        <div className={style.resultLine}>
            <InputTextComp
                title={"resultValue"}
                defaultText={receipt.resultValue}
                acceptChanges={(newValue) => {
                    receipt.resultValue = newValue
                    updateReceipt(receipt)
                }}
            />
        </div>
        <div className={style.resultLine}>
            <SelectComp
                defaultText={receipt.resultUnit}
                defaultSelectedValue={selectedUnit}
                values={dictionaryUnit}
                onChange={(e) => {
                    setSelectedUnit(e.shortName)
                    receipt.resultUnit = e.shortName
                    updateReceipt(receipt)
                }}
                attributeTitle={'name'}
            />
        </div>
    </div>
}