import {useNavigate} from "react-router-dom";
import style from "../../InfoPage.module.css";
import React, {useState} from "react";
import {DropdownMenu} from "../../../../../shared/ui/DropDownMenu/DropdownMenu";
import {useInfo} from "../../../lib/hooks/UseInfo";
import {DeletePageModal} from "../InfoDropDownMenuModal/DeletePageModal";
import {DrawExpandElement} from "../InfoSideMenu/DrawExpandElement";
import {AddPageModal} from "../InfoDropDownMenuModal/AddPageModal";
import {EditPageModal} from "../InfoDropDownMenuModal/EditPageModal";
import {useModal} from "../../../../../shared/hooks/useModal";

export const ListItem = ({itemData}) => {
    const {infoData, space, pageData} = useInfo()
    const {modal} = useModal()

    const history = useNavigate()
    const id = itemData['id'] ? itemData['id'].toString() : undefined
    const [showChildren, setShowChildren] = useState(!id)

    const modalDeleteContent = <DeletePageModal
        space={space}
        pageData={pageData}
        infoData={infoData}
        itemData={itemData}
    />

    const modalAddContent = <AddPageModal
        pageData={pageData}
    />

    const modalEditContent = <EditPageModal
        itemData={itemData}
        pageData={pageData}
    />

    const options = [
        {
            id: 1,
            title: "Add page",
            action: () => {
                modal.open(modalAddContent)
            },
        },
        {
            id: 2,
            title: "Delete page",
            action: () => {
                modal.open(modalDeleteContent)
            },
        },
        {
            id: 3,
            title: "Edit page",
            action: () => {
                modal.open(modalEditContent)
            },
        },
    ]


    return <div className={style.listItemWrapper}>
        <div className={style.listItemRow}>
            <DrawExpandElement
                id={id}
                itemData={itemData}
                setShowChildren={setShowChildren}
                showChildren={showChildren}
            />
            <div
                onMouseDown={(e) => {
                    if (e.button === 1 && !!id) {
                        e.stopPropagation()
                        window.open(infoData.generateHistoryPathByPageId(id))
                    }
                }}
                className={style.listItemPageName}
                onClick={() => {
                    if (!!id) {
                        history(infoData.generateHistoryPathByPageId(id))
                    }
                }}>
                {itemData.title}
            </div>
            <DropdownMenu options={options} id={itemData.id}/>
        </div>
        {showChildren && <div className={style.listItemChildren}>
            {itemData.children
                .sort((a, b) => {
                    let A = (!!a ? a : 0)
                    let B = (!!b ? b : 0)
                    return A - B
                })
                .map(e => <ListItem
                    key={e.id}
                    itemData={e}
                />)}
        </div>}
    </div>
}