import style from "./InvisibleInputArea.module.css"
import {InputAreaComp} from "../InputArea/InputAreaComp";
export const InvisibleInputArea = ({
                                  readOnly,
                                  defaultText,
                                  acceptChanges,
                              }) => {
    return <div className={style.wrapper}>
        <InputAreaComp
            defaultText={defaultText}
            readOnly={readOnly}
            acceptChanges={acceptChanges}
            customStyle={style.invisibleTextArea}
        />
    </div>
}