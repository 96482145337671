import React, {useEffect, useRef, useState} from "react";
import style from "./Comment.module.css"
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";

export const Comment = ({comment, setComment, onBlur}) => {
    const [additionalOpen, setAdditionalOpen] = useState(false)
    const popupRef = useRef(null)

    const useOutsideAlerter = (ref, outsideClickHandler) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    outsideClickHandler()
                }
            }
            document.addEventListener("mousedown", handleClickOutside)
            return () => document.removeEventListener("mousedown", handleClickOutside)
        }, [outsideClickHandler, ref])
    }

    useOutsideAlerter(popupRef, () => {
        setAdditionalOpen(false)
        onBlur()
    })

    return <div className={style.commentButton}>
        <ButtonComp
            icon={comment ? ICONS.filledMessage : ICONS.emptyMessage}
            onClick={() => {
                setAdditionalOpen(!additionalOpen)
            }}
        />
        {additionalOpen && (
            <div className={style.additionalInfoWindow} ref={popupRef}>
                    <textarea
                        className={style.textArea}
                        value={comment}
                        autoFocus={true}
                        onChange={(e) => setComment(e.target.value)}
                        onBlur={onBlur}
                    />
            </div>
        )}
    </div>
}
