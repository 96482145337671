import style from "./Calendar.module.css"
import React from "react";
import {SvgDiagram} from "./SvgDiagram";

export const CashCalendarDates = ({onClick, dates, selectedDate, setOnMoveDay}) => {
    return <table>
        <tbody>
        <tr>{[{id: -100, name: 'Mo'}, {id: -101, name: 'Tu'}, {id: -102, name: 'We'}, {id: -103, name: 'Th'},
            {id: -104, name: 'Fr'}, {id: -105, name: 'Sa'}, {id: -106, name: 'Su'}]
            .map(e => <td key={e.id}>
                <div className={style.cellHeader + (e.name === 'Sa' || e.name === 'Su' ? ' ' + style.celWeekend : '')}>
                    {e.name}
                </div>
            </td>)
        }</tr>
        {!!dates && dates.map(line => <tr key={200 + line[0]}>{!!line && line.map((e, num) =>
            <td className={style.td} key={e}>
                {e > 0 && e < 100 ?
                    <div className={`${style.cell} ${num > 4 ? style.celWeekend : ''} ${
                        e === selectedDate.getDate() ? style.selectedDate : ''}`}
                         onClick={() => {
                             onClick(e)
                         }}
                         onMouseEnter={() => setOnMoveDay(e)}
                         onMouseLeave={() => setOnMoveDay(null)}
                    >
                        <div className={style.cellContent}>
                            <div>{e}</div>
                            <div className={style.svgGroup}>
                                <SvgDiagram
                                    currentDay={e}
                                    fieldMax={"maxExpense"}
                                    fieldCurrent={"expense"}
                                    color={"#ef5151"}
                                />
                                <SvgDiagram
                                    currentDay={e}
                                    fieldMax={"maxIncome"}
                                    fieldCurrent={"income"}
                                    color={"#20c320"}
                                />
                            </div>
                        </div>
                    </div> : <div/>}
            </td>
        )}</tr>)}
        </tbody>
    </table>
}
