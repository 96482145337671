import style from "../CookingReceiptDetails/CookingNormalMode.module.css"
import {InvisibleInputText} from "../../../../../shared/ui/InvisibleInputText";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useCooking} from "../../../lib/hooks/UseCooking";
import {useModal} from "../../../../../shared/hooks/useModal";
import {ModalOptions} from "../CookingModal/ModalOptions";

export const CookingEditModeHeader = ({receipt, setEditMode}) => {
    const {modal} = useModal()
    const {updateReceipt, deleteReceipt} = useCooking()

    const modalContent = <ModalOptions
        deleteReceipt={deleteReceipt}
        receipt={receipt}
        updateReceipt={updateReceipt}
    />

    return <div className={style.header}>
        <div className={style.receiptName}>
            <InvisibleInputText
                defaultText={receipt.name}
                acceptChanges={(newValue) => {
                    receipt.name = newValue
                    updateReceipt(receipt)
                }}
            />
        </div>
        <div className={style.buttons}>
            <ButtonComp
                icon={ICONS.options}
                onClick={() => {modal.open(modalContent)}}
            />
        </div>
        {receipt["allowEdit"] &&
            <div className={style.buttons}>
                <ButtonComp
                    icon={ICONS.pencil}
                    onClick={() => setEditMode(false)}
                    tooltipText={"close edit mode"}
                />
            </div>
        }
    </div>
}