import {useCash} from "../../../lib/hooks/UseCash";

export const SvgDiagram = ({currentDay, fieldMax, fieldCurrent, color}) => {
    const {chartData, getDataByDay} = useCash()

    const filteredData = getDataByDay(currentDay)
    const diagramHeight = 26
    const current = filteredData && filteredData.length > 0 ? filteredData[0][fieldCurrent] : 0
    const max = chartData ? chartData[fieldMax] : 0
    const currentHeight = max !== 0 ? (current / max) * diagramHeight : 0

    return currentHeight > 0 && <svg width={7} height={diagramHeight}>
        <rect
            x={0}
            y={diagramHeight - currentHeight}
            width={7}
            height={currentHeight}
            style={{fill: color}}
        />
    </svg>
}