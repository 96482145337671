import React from "react";
import style from "./Categories.module.css"
import {useCooking} from "../../../lib/hooks/UseCooking";

export const CategoryButton = ({element, onButtonClick}) => {
    return <div key={element.id} className={style.buttonItem}>
        <button className={style.button} onClick={() => onButtonClick(element.code)}>
            <img className={style.imageUrl} src={element["imageUrl"]} alt={element["name"]}/>
            <div className={style.description}>{element.description}</div>
        </button>
        <div className={style.foodName}>{element.name}</div>
    </div>
}

export const Categories = () => {
    const {categoriesData, selectFood} = useCooking()

    const handleButtonClick = (code) => {
        selectFood(code)
    }

    return <div className={style.buttonContainer}>
        {categoriesData.map((item) => (
            <CategoryButton key={item.id} element={item} onButtonClick={handleButtonClick}/>
        ))}
    </div>
}