import style from "./Calendar.module.css"
import React, {useEffect, useState} from "react";
import {getMonthDates} from "../../../../../shared/ui/DatePicker/lib/dateHelper";
import {CashCalendarDates} from "./CashCalendarDates";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {Periods} from "../../../../../shared/const/Periods";
import {DrawMonths} from "../../../../../shared/ui/DatePicker/ui/DrawMonths";
import {DrawYear} from "../../../../../shared/ui/DatePicker/ui/DrawYear";
import toast from "react-hot-toast";
import {useCash} from "../../../lib/hooks/UseCash";
import {CalendarButton} from "./CalendarButton";

export const Calendar = ({date, setDate}) => {
    const {getDataByDay} = useCash()

    const period = Periods
    const [dates, setDates] = useState(getMonthDates(date))
    const [showMode, setShowMode] = useState(0)
    const [onMoveDay, setOnMoveDay] = useState()

    const filteredData = getDataByDay(onMoveDay)

    useEffect(() => {setDates(getMonthDates(date))}, [date])

    return <div className={style.wrapper}>
        <div>
            <div className={style.line} onDoubleClick={() => setDate(new Date())}>
                <div className={style.dateElement}>
                    <CalendarButton
                        icon={ICONS.rotateLittleArrow}
                        onClick={() => {
                            setDate(period.DAY.increaseDate(date, -1))
                        }}
                    />
                    <div className={style.dateText} onClick={() => {
                        setShowMode(0)
                    }}>{date.getDate()}</div>
                    <CalendarButton
                        icon={ICONS.littleArrow}
                        onClick={() => {
                            setDate(period.DAY.increaseDate(date, 1))
                        }}
                    />
                </div>
                <div className={style.dateElement}>
                    <CalendarButton
                        icon={ICONS.rotateLittleArrow}
                        onClick={() => {
                            setDate(period.MONTH.increaseDate(date, -1))
                        }}
                    />
                    <div className={style.dateText} onClick={() => {
                        setShowMode(2)
                    }}>{date.toLocaleString('en-us', {month: 'short'})}</div>
                    <CalendarButton
                        icon={ICONS.littleArrow}
                        onClick={() => {
                            setDate(period.MONTH.increaseDate(date, 1))
                        }}
                    />
                </div>
                <div className={style.dateElement}>
                    <CalendarButton
                        icon={ICONS.rotateLittleArrow}
                        onClick={() => {
                            setDate(period.YEAR.increaseDate(date, -1))
                        }}
                    />
                    <div className={style.dateText} onClick={() => {
                        setShowMode(1);
                    }}>{date.getFullYear()}</div>
                    <CalendarButton
                        icon={ICONS.littleArrow}
                        onClick={() => {
                            setDate(period.YEAR.increaseDate(date, 1))
                        }}
                    />
                </div>
            </div>
            <div className={style.calendarDates}>
                {showMode === 0 && <CashCalendarDates
                    setOnMoveDay={setOnMoveDay}
                    dates={dates}
                    selectedDate={date}
                    onClick={(e) => {
                        let newDate = new Date(date.getFullYear(), date.getMonth(), e)
                        setDate(newDate)
                    }}
                />}
                {showMode === 1 && <DrawYear
                    defaultYear={date.getFullYear()}
                    onClickHandler={(event) => {
                        if (isNaN(event)) {
                            toast.error("Year must be number")
                            return
                        }
                        if (Number(event) < 2000 || Number(event) > 2100) {
                            toast.error("Year must be between 2000 and 2100")
                            return
                        }

                        let newDate = new Date(event, 0, 1)
                        setDate(newDate)
                        setShowMode(1)
                    }}
                />}
                {showMode === 2 && <DrawMonths
                    onClickHandler={(e) => {
                        let newDate = new Date(date.getFullYear(), e.id, 1)
                        setDate(newDate)
                        setShowMode(0)
                    }}
                />}
            </div>
            <div>
                {filteredData.map((e, id) => (
                    <div key={id}>
                        <div>{new Date(e["day"]).toLocaleDateString()}</div>
                        <div style={{color: "green"}}>income: {e["income"]}</div>
                        <div style={{color: "red"}}>expense: {e["expense"]}</div>
                        <div>sum: {e["sum"]}</div>
                        <div style={{color: "#4343d1"}}>balance: {e["balance"]}</div>
                    </div>
                ))}
            </div>
        </div>
    </div>
}
