import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {useState} from "react";
import style from "./AddPageModal.module.css"
import {useModal} from "../../../../../shared/hooks/useModal";

export const AddPageModal = ({pageData}) => {
    const {modal} = useModal()
    const [newPageName, setNewPageName] = useState("")

    const handeCreateButton = () => {
        pageData.createPage(newPageName)
        modal.close()
        setNewPageName("")
    }

    return <div className={style.wrapper}>
        <h3 className={style.topText}>
            Create Page
        </h3>
        <div className={style.input}>
            <InputTextComp
                title={"New page name"}
                acceptChanges={setNewPageName}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handeCreateButton()
                    }
                }}
            />
        </div>
        <div className={style.buttonWrapper}>
            <div className={style.button}>
                <ButtonComp
                    text={"Cancel"}
                    onClick={() => {
                        modal.close()
                    }}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    text={"Create"}
                    onClick={() => {
                        handeCreateButton()
                    }}
                />
            </div>
        </div>
    </div>
}