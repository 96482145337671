import style from "./AddLine.module.css"
import {CheckBox} from "../../../../../shared/ui/CheckBox";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import React, {useState} from "react";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {useCash} from "../../../lib/hooks/UseCash";
import {Comment} from "../CommentButton/Сomment";
import {ProfitButton} from "../ProfitButton/ProfitButton";

export const AddLine = () => {
    const {addTransaction} = useCash()

    const [value, setValue] = useState(undefined)
    const [product, setProduct] = useState("")
    const [comment, setComment] = useState("")
    const [completed, setCompleted] = useState(false)
    const [isPositive, setIsPositive] = useState(false)

    const clearFields = () => {
        setValue(undefined)
        setProduct("")
        setComment("")
        setCompleted(false)
        setIsPositive(false)
    }

    const handleAddButton = () => {
        addTransaction(value, product, comment, completed, clearFields, isPositive)
    }

    return <div className={style.wrapper}>
        <div className={style.completedButton}>
            <CheckBox
                defaultIsChecked={completed}
                onChange={setCompleted}
            />
        </div>
        <div className={style.element}>
            <InputTextComp
                title={"product"}
                acceptChanges={setProduct}
                defaultText={product}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        addTransaction(value, product, comment, completed, clearFields)
                    }
                }}
            />
        </div>
        <div className={style.profitButton}>
            <ProfitButton
                isPositive={isPositive}
                onClick={setIsPositive}
            />
        </div>
        <div className={style.element}>
            <InputTextComp
                title={"value"}
                acceptChanges={setValue}
                defaultText={value}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleAddButton()
                    }
                }}
            />
        </div>
        <Comment
            comment={comment}
            setComment={setComment}
            onBlur={() => setComment("")}
        />
        <div className={style.addButton}>
            <ButtonComp
                text={"add"}
                onClick={() => {
                    handleAddButton()
                }}
            />
        </div>
    </div>
}