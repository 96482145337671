import React, {useEffect, useRef, useState} from "react";
import style from "./InputText.module.css";

export const InputTextComp = ({
                                  title = "",
                                  defaultText = "",
                                  acceptChanges = () => {},
                                  type = "text",
                                  onChange = () => {},
                                  updateTrigger,   // each change invokes the `useEffect` logic
                                  onKeyPress = () => {},
                                  readOnly = false,
                                  inputRef,
                                  autoFocus = false,
                              }) => {
    const [focus, setFocus] = useState(false)
    const [text, setText] = useState(defaultText)
    const innerRef = useRef(null)

    useEffect(() => {
        if (autoFocus) {
            setTimeout(() => innerRef.current.focus(), 100);
        }
    }, [])

    const refHandler = el => {
        innerRef.current = el
        if(inputRef) {
            inputRef.current = el
        }
    }

    const onAccept = () => {
        setFocus(false)
        if (!!acceptChanges && defaultText !== text) {
            acceptChanges(text)
        }
    }

    useEffect(() => {
        setText(defaultText)
    },[defaultText, updateTrigger])

    return <div className={style.wrapper}>
        <div className={`${style.placeholder} ${focus || !!text || String(text) === '0' ? style.placeholderRaise : style.placeholderNormal}`}>
            {title}
        </div>
        <input className={style.input}
               readOnly={readOnly}
               ref={refHandler}
               type={type}
               value={text}
               onFocus={() => setFocus(true)}
               onBlur={onAccept}
               onKeyDown={(e) => {
                   if (e.key === 'Enter') {

                       onAccept()
                   }
               }}
               onKeyPress={onKeyPress}
               onChange={(e) => {
                   e.persist();
                   setText(e.target.value)
                   onChange(e)
               }}
        />
    </div>
}

