import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import style from "./CreateReceiptModal.module.css"
import {useState} from "react";
import {SelectComp} from "../../../../../shared/ui/Select/SelectComp";
import {InvisibleInputArea} from "../../../../../shared/ui/InvisibleInputArea/InvisibleInputArea";
import {useModal} from "../../../../../shared/hooks/useModal";

export const CreateReceiptModal = ({addReceipt, dictionaryUnit, selectedCategory}) => {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [resultValue, setResultValue] = useState("")
    const [groupName, setGroupName] = useState("")
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [file, setFile] = useState(null)
    const {modal} = useModal()

    const isPublic = true

    const handleFileChange = (e) => {
        setFile(e.target.files[0])
    }

    return <div className={style.wrapper}>
        <div className={style.inputGroup}>
            <div className={style.input}>
                <InputTextComp
                    title={"name"}
                    defaultText={name}
                    acceptChanges={setName}
                />
            </div>
            <div>
                <input className={style.input} type="file" onChange={handleFileChange}/>
            </div>
            <div className={style.inputArea}>
                <InvisibleInputArea
                    defaultText={description}
                    acceptChanges={setDescription}
                />
            </div>
            <div className={style.input}>
                <InputTextComp
                    title={"result value"}
                    defaultText={resultValue}
                    acceptChanges={setResultValue}
                />
            </div>
            <div className={style.input}>
                <SelectComp
                    defaultText={"Select unit"}
                    defaultSelectedValue={selectedUnit}
                    values={dictionaryUnit}
                    onChange={(e) => {setSelectedUnit(e)}}
                    attributeTitle={'name'}
                />
            </div>
            <div className={style.input}>
                <InputTextComp
                    title={"group name"}
                    defaultText={groupName}
                    acceptChanges={setGroupName}
                />
            </div>
        </div>
        <div className={style.buttonGroup}>
            <div className={style.button}>
                <ButtonComp
                    text={"close"}
                    onClick={() => {modal.close()}}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    text={"add"}
                    onClick={() => {
                        addReceipt(name, description, resultValue, selectedUnit.shortName, isPublic, selectedCategory.id, file, groupName)
                        modal.close()
                    }}
                />
            </div>
        </div>
    </div>
}
