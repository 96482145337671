import style from "../../ui/WysiwygEditor.module.css";
import {ButtonComp} from "../../../Button/ButtonComp";
import React from "react";
import {ICONS} from "../../../img/icons/icons";
import {createTableColumn, createTableRow, getTableUnderCaret} from "./TableControls";
import {isInsideTags} from "../../../../lib/TextHtmlHelper";


export const AdditionalButtons = ({refWorkSpace}) => {
    const onBtnClick = {
        insertLeft: () => {
            createTableColumn(refWorkSpace, "before")
        },
        insertRight: () => {
            createTableColumn(refWorkSpace, "after")
        },
        insertUp: () => {
            createTableRow(refWorkSpace, "before")
        },
        insertDown: () => {
            createTableRow(refWorkSpace, "after")
        },
        deleteRow: () => {
            let tbl = getTableUnderCaret()
            if (!tbl) return

            if (tbl.rowsArray.length === 1)
                tbl.table.remove()
            else
                tbl.table.deleteRow(tbl.currentRowIndex)
        },
        deleteColumn: () => {
            let tbl = getTableUnderCaret()
            if (!tbl) return

            // find cell position in row
            let cellRowPosition = tbl.currentColIndex

            if (tbl.columnCount > 1) {
                for (let i = tbl.rowsArray.length - 1; i >= 0; i--)
                    tbl.rowsArray[i].deleteCell(cellRowPosition)
            } else {
                tbl.table.remove()
            }
        },
    }

    return <div className={style.additionalButtons}>
        {isInsideTags(refWorkSpace, "TABLE") &&
        <div className={style.tableButtons}>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableColumnInsertUp}
                    onClick={onBtnClick.insertUp}
                    tooltipText={"insert row above"}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableColumnInsertDown}
                    onClick={onBtnClick.insertDown}
                    tooltipText={"insert row below"}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableColumnInsertLeft}
                    onClick={onBtnClick.insertLeft}
                    tooltipText={"insert column left"}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableColumnInsertRight}
                    onClick={onBtnClick.insertRight}
                    tooltipText={"insert column right"}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableDeleteRow}
                    onClick={onBtnClick.deleteRow}
                    tooltipText={"delete row"}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    icon={ICONS.tableDeleteColumn}
                    onClick={onBtnClick.deleteColumn}
                    tooltipText={"delete column"}
                />
            </div>
        </div>
        }
    </div>
}