import {createContext, useContext, useEffect, useState} from "react";
import {
    createReceipt,
    deleteReceiptRecord,
    getCategoriesData,
    getDictionaryUnit, getReceiptData,
    updateReceiptRecord,
} from "../../api/Request";
import {useNavigate, useParams} from "react-router-dom";

const CookingDataContext = createContext(null)

export const useCooking = () => {
    return useContext(CookingDataContext)
}

export const CookingDataProvider = ({children}) => {
    const [categoriesData, setCategoriesData] = useState([])
    const [dictionaryUnit, setDictionaryUnit] = useState([])
    const [receiptData, setReceiptData] = useState([])
    const history = useNavigate()
    const {category} = useParams()

    const [selectedCategory, setSelectedCategory] = useState()

    useEffect(() => {
        getDictionaryUnit(setDictionaryUnit)
        getCategoriesData(setCategoriesData)
    }, [])

    useEffect(() => {
        const categoryData = categoriesData.find((e) => e.code === category)
        setSelectedCategory(categoryData || null)
    }, [category, categoriesData])

    const refreshData = () => {
        getReceiptData(setReceiptData)
    }

    const updateReceipt = (newReceipt, setUpdatedReceipt) => {
        updateReceiptRecord(newReceipt, (response) => {
            refreshData()
            setUpdatedReceipt && setUpdatedReceipt(response)
        })
    }

    const addProduct = (setSelectedReceipt, receipt, productName, value, unitShort) => {
        receipt["receiptProducts"].push({
            productName: productName,
            value: value,
            unitShort: unitShort,
        })
        updateReceipt(receipt, setSelectedReceipt)
    }

    const deleteProduct = (product, receipt) => {
        if(!product) {
            return
        }
        receipt["receiptProducts"] = receipt["receiptProducts"].filter((e) => {
            return e.id !== product
        })
        updateReceipt(receipt)
    }

    const deleteReceipt = (id) => {
        deleteReceiptRecord(id, refreshData)
    }

    const addReceipt = (name, description, resultValue, resultUnit, isPublic, categoryId, file, groupName) => {
        createReceipt(name, description, resultValue, resultUnit, isPublic, categoryId, file, groupName, refreshData)
    }

    const generateHistoryPathByFoodId = (foodCode) => {
        let path = "/cooking"
        if (foodCode !== null) {
            path += "/" + foodCode
        }
        return path
    }

    const selectFood = (foodCode) => {
        history(generateHistoryPathByFoodId(foodCode))
    }

    const cookingData = {
        deleteProduct: deleteProduct,
        addProduct: addProduct,
        receiptData: receiptData,
        setReceiptData: setReceiptData,
        deleteReceipt: deleteReceipt,
        updateReceipt: updateReceipt,
        dictionaryUnit: dictionaryUnit,
        selectedCategory: selectedCategory,
        categoriesData: categoriesData,
        addReceipt: addReceipt,
        selectFood: selectFood,
    }

    return <CookingDataContext.Provider value={cookingData}>{children}</CookingDataContext.Provider>
}