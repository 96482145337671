import {useEffect, useState} from "react";
import {isInsideTags} from "../../../lib/TextHtmlHelper";
import {addGlobalEventListener, dispatchGlobalEvent, removeGlobalEventListener} from "../../../lib/Listener";
import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";

export const H1Button = ({refWorkSpace}) => {
    const [activeButton, setActiveButton] = useState(false)
    const handleGlobalEvent = () => {
        setActiveButton(!!isInsideTags(refWorkSpace, "H1"))
    }

    useEffect(() => {

        addGlobalEventListener(handleGlobalEvent)

        return () => {
            removeGlobalEventListener(handleGlobalEvent)
        }
    }, [])

    return <div className={style.button}>
        <ButtonComp
            icon={ICONS.typeH1}
            onClick={() => {
                document.execCommand('formatBlock', false,  'h1')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={"Header 1"}
            isActive={activeButton}
        />
    </div>
}