import style from "../CookingReceiptDetails/CookingNormalMode.module.css"
import {useCooking} from "../../../lib/hooks/UseCooking";
import {InputAreaComp} from "../../../../../shared/ui/InputArea/InputAreaComp";

export const CookingEditModeDescription = ({receipt, dataFile}) => {
    const {updateReceipt} = useCooking()

    return <div className={style.description}>
        <div className={style.inputArea}>
            <InputAreaComp
                defaultText={receipt.description}
                acceptChanges={(newValue) => {
                    receipt.description = newValue
                    updateReceipt(receipt)
                }}
            />
        </div>
        <div className={style.imageContainer}>
            <img className={style.image} src={dataFile} alt="Receipt image"/>
        </div>
    </div>
}