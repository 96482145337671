import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {deletePage} from "../../../api/request";
import toast from "react-hot-toast";
import React from "react";
import {useNavigate} from "react-router-dom";
import style from "./DeletePageModal.module.css"
import {useModal} from "../../../../../shared/hooks/useModal";

export const DeletePageModal = ({itemData, infoData, pageData, space}) => {
    const {modal} = useModal()
    const history = useNavigate()

    return <div className={style.wrapper}>
        <div className={style.topText}>
            <h3>Delete page?</h3>
        </div>
        <div className={style.middleText}>
            This will delete <strong className={style.middleTextPart}>&nbsp;{itemData.title}</strong>
        </div>
        <div className={style.buttonWrapper}>
            <div className={style.button}>
                <ButtonComp
                    text={"Cancel"}
                    onClick={() => {modal.close()}}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    text={"Delete"}
                    onClick={() => {
                        deletePage(space.selectedSpace.id, pageData.parentId, () => {
                            pageData.updatePageList()
                            pageData.setParentId(undefined)
                            toast.success("Deleted")
                            history(infoData.generateHistoryPathByPageId(null, space.selectedSpace))
                            modal.close()
                        })
                    }}
                />
            </div>
        </div>
    </div>
}