//функция для преобразования миллисекунд без добавления часового пояса
//millisecond => date
export const GetDateWithGmt = (milliseconds) => {
    const date = new Date(milliseconds);

    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

// функция для преобразования даты в миллисекунды без гмт
//date => millisecond
export const GetMillisWithGmt = (date) => {
    const inputDate = date === null ? new Date() : new Date(date)

    const timezoneOffset = inputDate.getTimezoneOffset()

    inputDate.setHours(inputDate.getHours() - timezoneOffset / 60)

    return inputDate.getTime()
}

export const getFirstDayOfMonth = (date) => GetMillisWithGmt(new Date(date.getFullYear(), date.getMonth(), 1).getTime())

export const getFirstDayOfNextMonth = (date) => GetMillisWithGmt(new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime())
