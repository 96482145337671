import style from "./Buttons.module.css";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {dispatchGlobalEvent} from "../../../lib/Listener";

export const ClearStyleButton = ({refWorkSpace}) => {
    return <div className={style.clearButton}>
        <ButtonComp
            icon={ICONS.eraser}
            onClick={() => {
                document.execCommand('removeFormat', false,  '')
                refWorkSpace.current.focus()
                dispatchGlobalEvent("caretIsEdited")
            }}
            tooltipText={"Clear styles"}
        />
    </div>
}