const listeners = new Set();

export const addGlobalEventListener = (listener) => {
    listeners.add(listener)
}

export const removeGlobalEventListener = (listener) => {
    listeners.delete(listener)
}

export const dispatchGlobalEvent = (event) => {
    listeners.forEach((e) => e(event))
}