import {sendDeleteMsg, sendGetMsg, sendPostMsg, sendPutMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";

export const getChartData = (start, end, setChartData) => {
    sendGetMsg(URLS.CASH_REPORT + "/transaction",
        {
            "start": start,
            "end": end
        },
        (response) => {
            setChartData(response)
        }
    )
}

export const updateRecord = (newTransaction, refreshData) => {
    sendPutMsg( URLS.CASH_TRANSACTION,
        newTransaction,
        refreshData
    )
}

export const addRecord = (date, value, product, comment, completed, isPositive, refreshData) => {
    sendPostMsg( URLS.CASH_TRANSACTION,
        {
            "date": date,
            "value": value,
            "product": {
                "name": product
            },
            "comment": comment,
            "completed": completed,
            "isPositive": isPositive,
        },
        refreshData
    )
}

export const deleteRecord = (id, refreshData) => {
    sendDeleteMsg(URLS.CASH_TRANSACTION + "/" + id,
        null,
        refreshData
    )
}