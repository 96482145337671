import {useEffect} from "react";

export const UseWindowSize = (refBox, arr, top) => {
    const changeWindowSize = () => {
        if(refBox.current) {
            refBox.current.style.width = `${window.innerWidth - arr.sideBarWidth - 10}px`
            refBox.current.style.height = `${window.innerHeight - top}px`
        }
    }

    useEffect(() => {
        changeWindowSize()
        window.addEventListener('resize', changeWindowSize)

        return () => {
            window.removeEventListener('resize', changeWindowSize)
        }
    }, arr)
}