import style from './WysiwygEditor.module.css';
import React, {useEffect, useRef, useState} from "react";
import {DrawButtons} from "./DrawButtons";
import {AdditionalButtons} from "../buttons/tableButton/AdditionalButtons";
import {getCaretPosition} from "../lib/CaretPosition";
import {ButtonComp} from "../../Button/ButtonComp";
import {ICONS} from "../../img/icons/icons";
import {useInfo} from "../../../../pages/Info/lib/hooks/UseInfo";
import {UseWindowSize} from "../../../hooks/useWindowSize";
import {TextColor} from "../buttons/TextColor";
import {dispatchGlobalEvent} from "../../../lib/Listener";

export const WysiwygEditor = ({text, setText, hasUnsavedChanges, sideBarWidth}) => {
    const {pageData} = useInfo()
    const arr = [text, sideBarWidth, pageData.getMode()]
    const refWorkSpace = useRef(null)
    const [tmpText, setTmpText] = useState(text)
    const [caretPosition, setCaretPosition] = useState({})

    UseWindowSize(refWorkSpace, arr, 170)

    const syncText = () => {
        if (refWorkSpace.current) {
            setText(refWorkSpace.current.innerHTML);
        }
    }

    //calc caret position to redraw the component
    const updateCaretPosition = () => {
        if (caretPosition) {
            const position = getCaretPosition(refWorkSpace.current)
            setCaretPosition(position)
            dispatchGlobalEvent("caretIsEdited")
        }
    }

    useEffect(() => {
        if (refWorkSpace && refWorkSpace.current) {
            refWorkSpace.current.focus()
        }
    },[])

    return <div className={style.wysiwygContent}>
        <div className={style.buttonGroup}>
            <div className={style.editorButtons}>
                <DrawButtons
                    refWorkSpace={refWorkSpace}
                    syncText={syncText}
                />
                <TextColor
                    refWorkSpace={refWorkSpace}
                    syncText={syncText}
                />
                <AdditionalButtons
                    refWorkSpace={refWorkSpace}
                />
            </div>
            <div className={style.showCode}>
                {pageData.getMode() === "edit" && <ButtonComp
                    icon={ICONS.arrowDown}
                    onClick={() => {
                        pageData.toggleMode("html")
                    }}
                />}
                {pageData.getMode() === "html" && <ButtonComp
                    icon={ICONS.arrowDown}
                    onClick={() => {
                        pageData.toggleMode("edit")
                    }}
                />}
            </div>
        </div>
        <div className={style.contentWrapper}>
            {pageData.getMode() === "edit" &&
                <div className={`${style.wrapper} ${style.textContainer} ${hasUnsavedChanges ? style.needToSave : style.notNeedToSave}`}
                     dangerouslySetInnerHTML={{ __html: tmpText }}
                     contentEditable
                     onInput={(e) => {
                         setText(e.currentTarget.innerHTML)
                     }}
                     ref={refWorkSpace}
                     onClick={() => {
                         updateCaretPosition()
                         syncText()
                     }}
                     onKeyUp={() => {
                         updateCaretPosition()
                         syncText()
                     }}
                />
            }
            {pageData.getMode() === "html" &&
                <div className={`${style.wrapper} ${hasUnsavedChanges ? style.needToSave : style.notNeedToSave}`}>
                    <textarea
                        className={style.editor}
                        value={text || ''}
                        onChange={e => {
                            setText(e.target.value)
                            setTmpText(e.target.value)
                            syncText()
                        }}
                    />
                </div>
            }
        </div>
    </div>
}