import React, {useState} from "react";
import style from "./styles.module.css";
import {TaskData} from "../../../model/TaskData";
import {elementComparatorByName} from "../../../../../shared/lib/Comparator";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {TaskButton} from "../TaskButton/TaskButton";
import {DoingsTaskList} from "../DoingsTaskList/DoingsTaskList";
import {DoingsLabelList} from "../DoingsLabelList/DoingsLabelList";
import {useModal} from "../../../../../shared/hooks/useModal";

export const DoingsTasks = ({changeShowReport}) => {
    const {modal} = useModal()

    const {tasks, taskActions} = TaskData()

    const [showTaskList, setShowTaskList] = useState(false)

    function openLabelDialogue(task) {
        modal.open(<DoingsLabelList
            currentTask={task}
            taskActions={taskActions}
        />)
    }


    return <div className={style.wrapper}>

        <div className={style.line}>
            <div>
                <ButtonComp
                    text={"taskList"}
                    onClick={() => {setShowTaskList(!showTaskList)}}
                />
                <ButtonComp
                    text={"report"}
                    onClick={changeShowReport}
                />
            </div>


            <div className={style.activeTasks}>
                {!!tasks && tasks
                    .filter(e => e['checked'] === true)
                    .sort(elementComparatorByName)
                    .map(e => <TaskButton key={e['id']} element={e} taskActions={taskActions} />)}
            </div>

        </div>

        <div className={style.taskList}>
            {showTaskList && <DoingsTaskList tasks={tasks} taskActions={taskActions} openLabelDialogue={openLabelDialogue}/>}
        </div>
    </div>
}