import React, {useEffect, useState} from "react";
import style from "./FeedingPage.module.css";
import {InputTextComp} from "../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../shared/ui/Button/ButtonComp";
import {createAccess, getAccessStatus, setInviteString} from "../api/request";
import {FeedingData} from "./parts/FeedingData/FeedingData";
import {str} from "../../../shared/const/Language";

export const FeedingPage = () => {
    const [isPageCreated, setIsPageCreated] = useState(false)

    useEffect(() => {if (!isPageCreated) getAccessStatus(setIsPageCreated)}, [isPageCreated])

    if (isPageCreated)
        return <FeedingData/>
    else
        return <div className={style.wrapper}>
            <h2>{str("First run")}</h2>

            <div className={style.createNewButton}>
                <ButtonComp
                    text={str("Create new")}
                    onClick={() => createAccess(setIsPageCreated)}
                />
            </div>
            {str("or")}
            <InputTextComp
                title={str("Use access string")}
                hideOkBtn={false} // TODO: make this function
                acceptChanges={(newVal) => {
                    if (!!newVal)
                        setInviteString(newVal, setIsPageCreated)
                }}
            />

        </div>
}