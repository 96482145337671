import style from "./CookingNormalMode.module.css"
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {InvisibleInputText} from "../../../../../shared/ui/InvisibleInputText";
import {InvisibleInputArea} from "../../../../../shared/ui/InvisibleInputArea/InvisibleInputArea";
import {useEffect, useState} from "react";
import {loadFile} from "../../../api/Request";
import {ProductLine} from "../ProductLine/ProductLine";

export const CookingNormalMode = ({receipt, setEditMode}) => {
    const [dataFile, setDataFile] = useState([])

    useEffect(() => {
        loadFile(setDataFile, receipt.imageUrl)
    }, [receipt]);

    return <div className={style.wrapper}>
        <div className={style.header}>
            <div className={style.receiptName}>
                <InvisibleInputText
                    defaultText={receipt.name}
                    readOnly={true}
                />
            </div>
            {receipt["allowEdit"] &&
                <div className={style.buttons}>
                    <ButtonComp
                        icon={ICONS.pencil}
                        onClick={() => setEditMode(true)}
                        tooltipText={"show edit mode"}
                    />
                </div>
            }
        </div>
        <div className={style.created}>
            <div>
                group name: {receipt.groupName}
            </div>
            <div>
                created: {new Date(receipt.created).toLocaleDateString()}
            </div>
        </div>
        <div className={style.description}>
            <div className={style.inputArea}>
                <InvisibleInputArea
                    defaultText={receipt.description}
                    readOnly={true}
                    border={"none"}
                />
            </div>
            <div className={style.imageContainer}>
                <img className={style.image} src={dataFile} alt="Receipt image"/>
            </div>
        </div>
        {receipt["receiptProducts"].length > 0 &&
            <div className={style.viewProducts}>
                <table>
                    <thead>
                    <tr>
                        <th className={style.Th}>product name</th>
                        <th className={style.Th}>value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {receipt["receiptProducts"].map((e) => (
                        <ProductLine
                            product={e}
                            key={e.id}
                            readOnly={true}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        }
        <div className={style.receipt}>
            <div className={style.resultLine}>
                <InputTextComp
                    readOnly={true}
                    title={"resultValue"}
                    defaultText={receipt.resultValue + " " + receipt.resultUnit}
                />
            </div>
        </div>
    </div>
}