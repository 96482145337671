import {useEffect, useState} from "react";
import style from "./ProfitButton.module.css"
import {ICONS} from "../../../../../shared/ui/img/icons/icons";

export const ProfitButton = ({
                                 isPositive = false,
                                 onClick,
}) => {
    const [checked, setChecked] = useState(isPositive)

    useEffect(() => {
        setChecked(isPositive)
    }, [isPositive])

    const onClickHandler = () => {
        onClick(!checked)
        setChecked(!checked)
    }


    return <div className={style.wrapper} onClick={onClickHandler}>
        <div className={style.element}>
            {checked ? ICONS.plusCircle : ICONS.minusCircle}
        </div>
    </div>
}