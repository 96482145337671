import {createContext, useContext, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {createPageRequest, editPageRequest, getPage, getPageList, getSpacesList, savePage} from "../../api/request";
import toast from "react-hot-toast";

const InfoDataContext = createContext(null)

export const useInfo = () => {
    return useContext(InfoDataContext)
}

export const InfoDataProvider = ({children}) => {
    const {spaceId} = useParams()
    const {pageId} = useParams()
    const {visibility} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const PRIVATE = "private"
    const PUBLIC = "public"

    // common attrs
    const history = useNavigate()
    const [isPrivate, setIsPrivate] = useState(visibility === PRIVATE)
    const [spaceList, setSpaceList] = useState([])
    const [selectedSpace, setSelectedSpace] = useState(null)
    const [originalPageText, setOriginalPageText] = useState("")
    const [tmpPageText, setTmpPageText] = useState("")
    const [page, setPage] = useState({})
    const [pageList, setPageList] = useState([])
    const [parentId, setParentId] = useState()

    useEffect(() => {
        if (!!pageId) {
            console.log("LOAD PAGE #" + pageId)
            getPage(spaceId, pageId, setPageData)
        }
        // eslint-disable-next-line
    },[pageId])

    function updatePageList(tst = () => {}) {
        getPageList(selectedSpace['id'], (e) => {
            setPageList(e)
            tst()
        })
    }

    const getMode = () => {
        if (["normal", "edit", "html"].includes(searchParams.get("mode"))) {
            return searchParams.get("mode")
        }

        setMode("normal")
        return "normal"
    }
    const setMode = (newValue) => {
        searchParams.set("mode", newValue)
    }

    const toggleMode = (newValue) => {
        setMode(newValue)
        const params = new URLSearchParams(searchParams)
        params.set('mode', newValue)
        setSearchParams(params.toString())
    }

    const generateHistoryPathByPageId = (pageId, space = selectedSpace) => {
        let path = "/info/" + (isPrivate ? PRIVATE : PUBLIC)
        if (!!space) {
            path += "/" + space['id']
            if (!!pageId) {
                path += "/" + pageId
            }
        }
        return path
    }
    const editPage = (id, title, OldPageTitle) => {
        editPageRequest(id, title, selectedSpace.id, () => {
            updatePageList(() => toast.success("Page '" + OldPageTitle + "' edited"))
        })
    }

    const createPage = (title) => {
        createPageRequest(selectedSpace.id, title, parentId, () => {
            updatePageList(() => toast.success("InfoContent '" + title + "' created"))
        })
    }

    const selectSpace = (space) => {
        console.log("Select space=", space)
        setSelectedSpace(space)
        history(generateHistoryPathByPageId(null, space))
    }

    const changeIsPrivate = (newValue = true) => {
        let newIsPrivate = !!newValue
        setIsPrivate(newIsPrivate)
        history("/info/" + (newIsPrivate ? PRIVATE : PUBLIC))
        getSpacesList(newIsPrivate, setSpaceList)
        setSelectedSpace(null)
    }

    const updatePage = (newValue) => {
        savePage(newValue, () => {
            getPage(spaceId, pageId, setPageData)
        })
    }

    const init = () => {
        // Load space list, then select page (if need)
        console.log("Init. isPrivate=" + isPrivate.toString() + ", spaceId=" + spaceId)
        getSpacesList(isPrivate, (list) => {
            setSpaceList(list)
            if (!!spaceId) {
                let el = list.find(e => e['id'].toString() === spaceId)
                if (!!el) {
                    setSelectedSpace(el)
                } else {
                    setSelectedSpace(null)
                    toast.error("Space id=" + spaceId + " not found")
                }
            }
        })
    }

    // eslint-disable-next-line
    useEffect(init,[])

    const setText = (newValue) => {
        setOriginalPageText(newValue)
        setTmpPageText(newValue)
    }

    const setPageData = (newValue) => {
        setPage(newValue)
        setText(newValue["html"])
        // toggleMode("normal")
    }

    useEffect(() => {
        toggleMode(searchParams.get("mode"))
    }, [searchParams]);

    const combinedData = {
        space: {
            spaceId: spaceId,
            selectSpace: selectSpace,
            selectedSpace: selectedSpace,
            spaceList: spaceList,
        },
        pageData: {
            createPage: createPage,
            editPage: editPage,
            setPageData: setPageData,
            setMode: setMode,
            getMode: getMode,
            // mode: mode,
            toggleMode: toggleMode,
            updatePageList: updatePageList,
            pageList: pageList,
            updatePage: updatePage,
            originalPageText: originalPageText,
            tmpPageText: tmpPageText,
            setTmpPageText: setTmpPageText,
            page: page,
            setParentId: setParentId,
            parentId: parentId,
        },
        infoData: {
            generateHistoryPathByPageId: generateHistoryPathByPageId,
            init: init,
            isPrivate: isPrivate,
            changeIsPrivate: changeIsPrivate,
        }
    }

    return <InfoDataContext.Provider value={combinedData}>{children}</InfoDataContext.Provider>
}