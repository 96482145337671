export const SERVER_ADDRESS = window.location.origin + "/api"
// export const SERVER_ADDRESS = "https://dev.routineninja.com/api"

export const URLS = {
    INFO_PAGE: "info-page",
    DOINGS_TASK: "doings-task",
    DOINGS_LABEL: "doings-label",
    CASH_WALLET: "cash-wallet",
    CASH_WALLET_CELL: "cash-wallet-cell",
    CASH_OPERATION: "cash-operation",
    CASH_CURRENCY: "cash-currency",
    CASH_REPORT: "cash-report",
    CASH_TRANSACTION: "cash-transaction",
    DOINGS_PERIOD: 'doings-log/period',
    INFO_SPACE: "info-space",
    COOKING_CATEGORY: "cooking-category",
    COOKING_RECEIPT: "cooking-receipt",
    DICTIONARY_UNIT: "dictionary-unit",
    FILES: "files",
}
