import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import style from "./EditPageModal.module.css"
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {useState} from "react";
import {useModal} from "../../../../../shared/hooks/useModal";

export const EditPageModal = ({itemData, pageData}) => {
    const {modal} = useModal()
    const [titlePage, setTitlePage] = useState(itemData.title)

    const handleEditButton = () => {
        pageData.editPage(itemData.id, titlePage, itemData.title)
        modal.close()
    }

    return <div className={style.wrapper}>
        <h3 className={style.topText}>
            Edit Page
        </h3>
        <div className={style.input}>
            <InputTextComp
                title={"New page name"}
                defaultText={titlePage}
                onChange={(e) => {
                    setTitlePage(e.target.value)
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleEditButton()
                    }
                }}
            />
        </div>
        <div className={style.buttonWrapper}>
            <div className={style.button}>
                <ButtonComp
                    text={"Cancel"}
                    onClick={() => {
                        modal.close()
                    }}
                />
            </div>
            <div className={style.button}>
                <ButtonComp
                    text={"Edit"}
                    onClick={() => {
                        handleEditButton()
                    }}
                />
            </div>
        </div>
    </div>
}