import {useEffect} from "react";

export const useClickOutside = (ref, setIsOpen) => {
    useEffect(() => {
        let handler = (e) => {
            if(!ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })
}