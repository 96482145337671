export const isInsideTags = (refWorkSpace, tagName) => {
    const selection = window.getSelection();

    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let currentNode = range.commonAncestorContainer;
        return parentElementIs(currentNode, tagName, refWorkSpace) !== null
    }
    return false
}

export function parentElementIs(element, name, refWorkSpace) {
    let currentElement = element;

    while (currentElement && currentElement !== refWorkSpace) {
        if (currentElement.nodeName === name) {
            return currentElement;
        }
        currentElement = currentElement.parentElement;
    }
    return null
}

export function isInsideStyle(refWorkSpace, styleName, value) {
    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        let currentNode = range.commonAncestorContainer

        if (currentNode.nodeType !== refWorkSpace) {
            currentNode = currentNode.parentElement
        }

        const computedStyle = window.getComputedStyle(currentNode)

        if (computedStyle && computedStyle[styleName] === value) {
            return true
        }
    }
    return false
}