import {useEffect, useRef} from "react";
import style from "./Resizer.module.css"

export const Resizer = ({
                            refBox,
                            width,
                            setWidth,
                            maxWidth = 600,
                            minWidth = 100
}) => {
    const refRight = useRef(null)

    useEffect(() => {
        const resizeableElement = refBox.current
        const styles = window.getComputedStyle(resizeableElement)

        width = parseInt(styles.width)
        setWidth(width)

        let xCord = 0

        //RIGHT
        const onMouseMoveRightResize = (event) => {
            const dx = event.clientX - xCord
            xCord = event.clientX
            width = width + dx

            if (width > maxWidth) {
                width = maxWidth
            }
            if (width < minWidth) {
                width = minWidth
            }

            resizeableElement.style.width = `${width}px`
            setWidth(width)
        }

        const onMouseUpRightResize = () => {
            document.removeEventListener("mousemove", onMouseMoveRightResize)
        }

        const onMouseDownRightResize = (event) => {
            xCord = event.clientX
            resizeableElement.style.left = styles.left
            resizeableElement.style.right = null
            document.addEventListener("mousemove", onMouseMoveRightResize)
            document.addEventListener("mouseup", onMouseUpRightResize)
        }

        const resizerRight = refRight.current
        resizerRight.addEventListener("mousedown", onMouseDownRightResize)

        return () => {
            resizerRight.removeEventListener("mousedown", onMouseDownRightResize)
        }
    }, [])

    return <div ref={refRight} className={style.resizerRr}>
        ||
    </div>
}