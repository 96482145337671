export function restoreCaretPosition(position) {
    if (position) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(position);
    }
}

export function setCaretPosition(editableElement, position) {
    let charIndex = 0
    let range = document.createRange();
    range.setStart(editableElement, 0);
    range.collapse(true);
    let nodeStack = [editableElement], node, foundStart = false, stop = false;

    while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType === 3) {
            let nextCharIndex = charIndex + node.length;
            if (!foundStart && position.start >= charIndex && position.start <= nextCharIndex) {
                range.setStart(node, position.start - charIndex);
                foundStart = true;
            }
            if (foundStart && position.end >= charIndex && position.end <= nextCharIndex) {
                range.setEnd(node, position.end - charIndex);
                stop = true;
            }
            charIndex = nextCharIndex;
        } else {
            let i = node.childNodes.length;
            while (i--) {
                nodeStack.push(node.childNodes[i]);
            }
        }
    }

    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}

export function calcCaretPosition() {
    return window.getSelection().getRangeAt(0)
}

export const getCaretPosition = (element) => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStart(element, 0);
    range.setEnd(selection.anchorNode, selection.anchorOffset);

    const textRange = range.toString().length;
    return { start: textRange, end: textRange };
}


