import React, {useEffect, useRef} from "react";
import style from "./InfoContent.module.css";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {WysiwygEditor} from "../../../../../shared/ui/WysiwygEditor";
import {useInfo} from "../../../lib/hooks/UseInfo";
import {UseWindowSize} from "../../../../../shared/hooks/useWindowSize";


export const InfoContent = ({sideBarWidth}) => {
    const {pageData} = useInfo()
    const refBox = useRef(null)
    const arr = [sideBarWidth, pageData.tmpPageText, pageData.getMode()]

    UseWindowSize(refBox, arr, 123)

    const changeText = (text) => {
        if(text === null) {
            return null
        }
        const codeTagRegex = /<code>(.*?)<\/code>/g;

        return text.replace(codeTagRegex, (match, codeContent) => {
            const onClickFunction = `onClick = "copyCode(this)"`

            return `<code ${onClickFunction}>${codeContent}</code>`
        })
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && ['s', 'S', 'ы', 'Ы'].includes(event.key)) {
                pageData.page.html = pageData.tmpPageText
                pageData.updatePage(pageData.page)
                event.preventDefault()
            }
        }

        window.addEventListener('keydown', handleKeyPress)
        return () => window.removeEventListener('keydown', handleKeyPress)
    }, [pageData])

    if (!pageData.page.id) return <div className={style.noContent}>
        no content
    </div>

    return <div className={style.wrapper} style={{marginLeft: sideBarWidth}}>
        <div className={style.actions}>
            {pageData.getMode() !== "normal" && <ButtonComp
                text={"normal"}
                onClick={() => {
                    pageData.toggleMode("normal")
                }}
            />}
            {pageData.getMode() === "normal" && <ButtonComp
                text={"edit"}
                onClick={() => {
                    pageData.toggleMode("edit")
                }}
            />}
            {pageData.getMode() === "edit" && (
                <ButtonComp
                    text={"save"}
                    onClick={() => {
                        pageData.page.html = pageData.tmpPageText
                        pageData.updatePage(pageData.page)
                    }}
                />
            )}
        </div>
        {pageData.getMode() !== "normal" &&
            <WysiwygEditor
                text={pageData.tmpPageText}
                setText={pageData.setTmpPageText}
                hasUnsavedChanges={pageData.originalPageText !== pageData.tmpPageText}
                sideBarWidth={sideBarWidth}
            />
        }
        {pageData.getMode() === "normal" &&
            <div ref={refBox} className={style.pageText} dangerouslySetInnerHTML={{__html: changeText(pageData.tmpPageText)}}/>
        }
        {/*// <div className={style.wrapper} dangerouslySetInnerHTML={{__html: updatedText}}/>*/}
        {/*        // использовать если получится сделать оповещение при копирований текста*/}
    </div>
}