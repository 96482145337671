import style from "../CookingReceiptDetails/CookingNormalMode.module.css"
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {SelectComp} from "../../../../../shared/ui/Select/SelectComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {useState} from "react";
import toast from "react-hot-toast";
import {useCooking} from "../../../lib/hooks/UseCooking";

export const CookingEditModeAddProduct = ({receipt, setSelectedReceipt}) => {
    const {addProduct, dictionaryUnit} = useCooking()

    const [value, setValue] = useState(undefined)
    const [productName, setProductName] = useState("")
    const [unitShort, setUnitShort] = useState("")

    const clearField = () => {
        setValue(undefined)
        setProductName("")
        setUnitShort("")
    }

    const onEnterPress = (e) => {
        if(productName !== "" && unitShort !== "" && value !== undefined) {
            if (e.key === 'Enter') {
                addProduct(setSelectedReceipt, receipt, productName, value, unitShort)
                clearField()
            }
            toast.success("added")
        }
    }

    return <div className={style.addProduct}>
        <div className={style.input}>
            <InputTextComp
                defaultText={productName}
                title={"product name"}
                acceptChanges={(newValue) => {
                    setProductName(newValue)
                }}
                onKeyPress={(e) => {
                    onEnterPress(e)
                }}
            />
        </div>
        <div className={style.input}>
            <InputTextComp
                defaultText={value}
                title={"value"}
                acceptChanges={(newValue) => {
                    setValue(newValue)
                }}
                onKeyPress={(e) => {
                    onEnterPress(e)
                }}
            />
        </div>
        <div className={style.input}>
            <SelectComp
                defaultText={unitShort}
                defaultSelectedValue={unitShort}
                values={dictionaryUnit}
                onChange={(e) => {
                    setUnitShort(e.shortName)
                }}
                attributeTitle={'name'}
            />
        </div>
        <div className={style.input}>
            <ButtonComp
                text={"add"}
                onClick={() => {
                    addProduct(setSelectedReceipt, receipt, productName, value, unitShort)
                    clearField()
                }}
            />
        </div>
    </div>
}