import style from "./ViewTransaction.module.css"
import {TransactionLine} from "./TransactionLine";

export const ViewTransaction = ({transactionList}) => {

    if (!transactionList || !transactionList.length) {
        return <div className={style.wrapper}>No data available</div>;
    }

    return <div className={style.wrapper}>
        <table>
            <thead>
            <tr>
                <th>Done</th>
                <th>Product</th>
                <th> </th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            {transactionList[0]["transactions"].map((e) => (
                <TransactionLine
                    transaction={e}
                    key={e.id}
                />
            ))}
            </tbody>
        </table>
    </div>
}