import style from "../InvisibleInputArea/InvisibleInputArea.module.css";
import {useEffect, useId, useState} from "react";
export const InputAreaComp = ({
                                       defaultText = "",
                                       acceptChanges,
                                       readOnly = false,
                                       customStyle,
                                   }) => {
    const id = useId()
    const [text, setText] = useState(defaultText)

    const onAccept = () => {
        if (!!acceptChanges && defaultText !== text) {
            acceptChanges(text)
        }
    }

    useEffect(() => {
        setText(defaultText)
    },[defaultText])

    return <div id={id} className={style.wrapper}>
        <textarea
            readOnly={readOnly}
            value={text}
            className={style.area + ' ' + customStyle}
            onChange={(e) => setText(e.target.value)}
            onBlur={onAccept}
        />
    </div>
}