import style from "./InfoSideMenu.module.css"
import React, {useEffect, useRef} from "react";
import {ListItem} from "../ListItem/ListItem";
import {useInfo} from "../../../lib/hooks/UseInfo";
import {Resizer} from "../../../../../shared/ui/Resizer/Resizer";

export const InfoSideMenu = ({sideBarWidth, setSideBarWidth}) => {
    const {pageData, space} = useInfo()
    const refBox = useRef(null)


    useEffect(() => {
        pageData.updatePageList()
        // eslint-disable-next-line
    }, [space.selectedSpace])

    return <div className={style.wrapper}>
        <div className={style.sideMenu} ref={refBox}>
            <div className={style.sideMenuContent}>
                {pageData.pageList.map((e) => <ListItem
                    key={e.id}
                    itemData={e}
                />)}
            </div>
            <Resizer
                refBox={refBox}
                width={sideBarWidth}
                setWidth={setSideBarWidth}
            />
        </div>
    </div>
}