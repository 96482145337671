import {sendDeleteMsg, sendGetMsg, sendPostMsg, sendPutMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";
import toast from "react-hot-toast";

export function getTaskList(setDataHandler) {
    sendGetMsg(URLS.DOINGS_TASK,
        {},
        setDataHandler,
        (e) => {toast.error(e)}
    )
}

export function updateTask(element) {
    sendPutMsg(URLS.DOINGS_TASK, element)
}

export function removeTask(element) {
    sendDeleteMsg(URLS.DOINGS_TASK + "/" + element['id'], {}, () => toast.success('Task "' + element['name'] + '" deleted'))
}

export function createTask(name, successHandler) {
    sendPostMsg(URLS.DOINGS_TASK, {"name": name}, successHandler)
}

export function getLabelList(setDataHandler) {
    sendGetMsg(URLS.DOINGS_LABEL,
        {},
        setDataHandler,
        e => {toast.error(e)}
    )
}
export function updateLabel(element) {
    sendPutMsg(URLS.DOINGS_LABEL, element)
}

export function removeLabel(element, refreshTaskList) {
    sendDeleteMsg(
        URLS.DOINGS_LABEL + "/" + element['id'],
        {},
        () => {
            toast.success('Label "' + element['name'] + '" deleted')
            refreshTaskList()
        },
        (ex) => toast.error("Error " + ex)
    )
}

export function createLabel(name, successHandler) {
    sendPostMsg(URLS.DOINGS_LABEL, {"name": name}, successHandler)
}

export function loadData(startDate, endDate, setDataHandler) {
    sendGetMsg(URLS.DOINGS_PERIOD,
        {
            start: startDate.getTime(),
            end: endDate.getTime()
        },
        setDataHandler,
        (e) => {console.log(e)}
    )
}