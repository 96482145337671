import React from "react";
import style from "./UserDetails.module.css";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {GetUsersList} from "../../../api/GetUsersList";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {UserRoleItem} from "../UserRoleItem/UserRoleItem";
import {useModal} from "../../../../../shared/hooks/useModal";

export const UserDetails = ({
                                login,
                                created,
                                lastLogin,
                                email,
                                roles,
                                allRoles,
                                setSelectedUser,
                                setUsers
                            }) => {
    const allAvailableRoles = allRoles.filter((role) => !roles.includes(role))
    const {modal} = useModal()

    const formatDate = (time) => {
        const date = new Date(time);
        return date.toLocaleString();
    };

    const updateUsers = () => {
        GetUsersList(setUsers);
    }

    const modalContent = <div className={style.modal}>
        <div>
            select a role: {allAvailableRoles.map((role) => (
            <UserRoleItem
                key={role}
                role={role}
                userName={login}
                canAdd={true}
                setSelectedUser={setSelectedUser}
                setUsers={setUsers}
                updateUsers={updateUsers}
            />
        ))}
        </div>
    </div>

    return <div className={style.wrapper}>
        <div className={style.userFullInfo}>
            <div className={style.userTextInfo}>
                <div className={style.inputData}>
                    <div className={style.inputLogin}>
                        <InputTextComp
                            title={"login"}
                            type={"text"}
                            defaultText={login}
                        />
                    </div>
                    <div className={style.inputEmail}>
                        <InputTextComp
                            title={"email"}
                            type={"text"}
                            defaultText={email}
                        />
                    </div>
                </div>
                <div className={style.userDate}>
                    <div>Created: {formatDate(created)}</div>
                    <div>LastLogin: {formatDate(lastLogin)}</div>
                </div>
            </div>
            <div className={style.roleGroup}>
                Roles: {roles.map((role) =>
                <UserRoleItem
                    key={role}
                    role={role}
                    userName={login}
                    canDelete={true}
                    setSelectedUser={setSelectedUser}
                    setUsers={setUsers}
                    updateUsers={updateUsers}
                />
            )}
                <div className={style.buttonAddRole}>
                    <ButtonComp
                        tooltipText={"Add role"}
                        icon={ICONS.plus}
                        onClick={() => {
                            modal.open(modalContent)
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
};
