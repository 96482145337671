import React, {useEffect, useId, useState} from 'react';
import {ButtonComp} from "../../Button/ButtonComp";
import {ReactComponent as CheckedIcon} from "../const/checked.svg";
import {ReactComponent as UncheckedIcon} from "../const/unchecked.svg";
import {Tooltip} from "react-tooltip";

export const CheckBox = ({
                             defaultIsChecked = true,
                             onChange = () => {},
                             tooltipText,
                         }) => {
    const [checked, setChecked] = useState(defaultIsChecked)
    const id = useId()

    useEffect(() => {
        setChecked(defaultIsChecked)
    }, [defaultIsChecked])

    function onClickHandler() {
        onChange(!checked)
        setChecked(!checked)
    }

    return <div data-tooltip-id={id}>
        <ButtonComp
            icon={checked ? <CheckedIcon/> : <UncheckedIcon/>}
            onClick={onClickHandler}
        />
        {<Tooltip
            style={{zIndex:5}}
            place={"bottom"}
            content={tooltipText}
            id={id}
            delayShow={500}
        />}
    </div>
}
