import style from './UserRoleItem.module.css';
import React from "react";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {addRole, deleteRole} from "../../../api/request";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import {useModal} from "../../../../../shared/hooks/useModal";

export const UserRoleItem = ({
                                 role,
                                 canDelete= false,
                                 userName,
                                 canAdd= false,
                                 updateUsers,
                                 setSelectedUser
                             }) => {
    const {modal} = useModal()

    const handleAddingRole = (role) => {
        addRole(userName, role, updateUsers, setSelectedUser)
    }

    const handleRemoveRole = (role) => {
        deleteRole(userName, role, updateUsers, setSelectedUser)
        modal.close()
    }

    const modalContent = <div className={style.modal}>
        <h4>Are you sure you want to delete role</h4>
        <div className={style.modalButtons}>
            <ButtonComp
                text={"Cancel"}
                onClick={() => {
                    modal.close()
                }}
            />
            <ButtonComp
                text={"Delete"}
                onClick={() => {
                    handleRemoveRole(role)
                }}
            />
        </div>
    </div>

    return <div className={style.wrapper}>
        <div className={style.roleWrapper} onClick={() => canAdd && handleAddingRole(role)}>
            <div className={style.role}>{role}</div>
            {canDelete && (
                <div className={style.crossIcon} onClick={() => {modal.open(modalContent)}}>
                    {ICONS.cross}
                </div>
            )}
        </div>
    </div>
}
