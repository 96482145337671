import style from "./DrawButtons.module.css"
import React from "react";
import {Link} from "../buttons/Link";
import {CodeBlock} from "../buttons/CodeBlock";
import {InsertTable} from "../buttons/tableButton/InsertTable";
import {UnderlineButton} from "../buttons/UnderlineButton";
import {BoldButton} from "../buttons/BoldButton";
import {ItalicButton} from "../buttons/ItalicButton";
import {H1Button} from "../buttons/H1Button";
import {H2Button} from "../buttons/H2Button";
import {H3Button} from "../buttons/H3Button";
import {UnorderedListButton} from "../buttons/UnorderedListButton";
import {OrderedListButton} from "../buttons/OrderedListButton";
import {AlignLeftButton} from "../buttons/AlignLeftButton";
import {AlignCenterButton} from "../buttons/AlignCenterButton";
import {AlignRightButton} from "../buttons/AlignRightButton";
import {ClearStyleButton} from "../buttons/ClearStyleButton";
import {NormalTextButton} from "../buttons/NormalTextButton";

export const DrawButtons = ({refWorkSpace, syncText}) => {
    return <div className={style.wrapper}>
        <ClearStyleButton refWorkSpace={refWorkSpace}/>
        <UnderlineButton refWorkSpace={refWorkSpace}/>
        <BoldButton refWorkSpace={refWorkSpace}/>
        <ItalicButton refWorkSpace={refWorkSpace}/>
        <NormalTextButton refWorkSpace={refWorkSpace}/>
        <H1Button refWorkSpace={refWorkSpace}/>
        <H2Button refWorkSpace={refWorkSpace}/>
        <H3Button refWorkSpace={refWorkSpace}/>
        <UnorderedListButton refWorkSpace={refWorkSpace}/>
        <OrderedListButton refWorkSpace={refWorkSpace}/>
        <AlignLeftButton refWorkSpace={refWorkSpace}/>
        <AlignCenterButton refWorkSpace={refWorkSpace}/>
        <AlignRightButton refWorkSpace={refWorkSpace}/>
        <div className={style.specialButtons}>
            <div className={style.button}>
                <Link
                    syncText={syncText}
                    refWorkSpace={refWorkSpace}
                />
            </div>
            <div className={style.button}>
                <CodeBlock
                    syncText={syncText}
                    refWorkSpaxe={refWorkSpace}
                />
            </div>
            <div className={style.button}>
                <InsertTable
                    syncText={syncText}
                    refWorkSpace={refWorkSpace}
                />
            </div>
        </div>
    </div>
}