import style from "../../InfoPage.module.css";
import {InputTextComp} from "../../../../../shared/ui/InputText/InputTextComp";
import {ButtonComp} from "../../../../../shared/ui/Button/ButtonComp";
import {createSpace} from "../../../api/request";
import React, {useState} from "react";

export const CreateSpaceModal = ({
                                     closeModal,
                                     isPrivate,
                                     updateSpaceList,
                                 }) => {
    const [newSpaceName, setNewSpaceName] = useState("")

    return <div className={style.modalWindow}>
        <h3>Create space</h3>
        <div className={style.modalElement}>
            <InputTextComp
                title={"Space Name"}
                acceptChanges={(newVal) => {
                    setNewSpaceName(newVal)
                }}
            />
        </div>
        <div className={style.modalElement}>
            <ButtonComp
                text={"Cancel"}
                minWidth={"5em"}
                tooltipText={"Cancel creating Space"}
                onClick={closeModal}
            />
            <ButtonComp
                text={"OK"}
                minWidth={"5em"}
                tooltipText={"Create Space"}
                onClick={() => {
                    createSpace(newSpaceName,
                        isPrivate,
                        () => {
                            updateSpaceList()
                            // TODO: select new space as active
                        })
                    closeModal()
                }}
            />
        </div>
    </div>
}
