import style from "./DrawExpandElement.module.css"
import {ICONS} from "../../../../../shared/ui/img/icons/icons";
import React from "react";
import {RawButton} from "../../../../../shared/ui/RawButton/RawButton";

export const DrawExpandElement = ({itemData, id, showChildren, setShowChildren}) => {
    if (itemData.children.length <= 0 || !id) return

    return showChildren
        ? <RawButton
            content={
                <div className={style.littleArrow}>
                    {ICONS.rotateLittleArrow}
                </div>
            }
            onClick={() => setShowChildren(false)}
        />
        : <RawButton
            content={
                <div className={style.rotateLittleArrow}>
                    {ICONS.littleArrow}
                </div>
            }
            onClick={() => setShowChildren(true)}
    />
}