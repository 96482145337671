import {RawButton} from "../../../../../shared/ui/RawButton/RawButton";
import style from "./CalendarButton.module.css"

export const CalendarButton = ({icon, onClick, tooltipText}) => {
    return <RawButton
        content={
            <div className={style.calendarButton} /*onClick={onClick}*/>
                {icon}
            </div>
        }
        onClick={onClick}
        tooltipText={tooltipText}
    />
}