import React, {useState} from "react";
import style from "./InfoPage.module.css";
import {ButtonComp} from "../../../shared/ui/Button/ButtonComp";
import {SelectComp} from "../../../shared/ui/Select/SelectComp";
import {CreateSpaceModal} from "./parts/CreateSpaceModal/CreateSpaceModal";
import {EditSpaceModal} from "./parts/EditSpaceModal/EditSpaceModal";
import {useInfo} from "../lib/hooks/UseInfo";
import {InvisibleInputText} from "../../../shared/ui/InvisibleInputText";
import {ICONS} from "../../../shared/ui/img/icons/icons";
import {InfoSideMenu} from "./parts/InfoSideMenu/InfoSideMenu";
import {InfoContent} from "./parts/InfoContent/InfoContent";
import {useModal} from "../../../shared/hooks/useModal";

export const InfoPage = () => {
    const {pageData, space, infoData} = useInfo()
    const {modal} = useModal()
    const [sidebarWidth, setSidebarWidth] = useState()

    const modalEditContent = <EditSpaceModal
        key={space.spaceId}
        closeModal={() => {
            modal.close()
        }}
        currentSpace={space.selectedSpace}
        updateSpaceList={infoData.init}
    />

    const modalCreateContent = <CreateSpaceModal
        closeModal={() => {
            modal.close()
        }}
        isPrivate={infoData.isPrivate}
        updateSpaceList={infoData.init}
    />

    return <div className={style.wrapper}>
        <div className={style.pagePart}>
            <div className={style.actionElement}>
                {
                    infoData.isPrivate
                        ? <ButtonComp
                            tooltipText={"Show public pages"}
                            icon={ICONS.lockClose}
                            onClick={() => infoData.changeIsPrivate(false)}
                        />
                        : <ButtonComp
                            tooltipText={"Show private pages"}
                            icon={ICONS.lockOpen}
                            onClick={() => infoData.changeIsPrivate(true)}
                        />
                }
            </div>
            <div className={style.actionElement}>
                <SelectComp
                    key={infoData.isPrivate ? 0 : 1}
                    defaultText={"Select space"}
                    defaultSelectedValue={space.selectedSpace}
                    values={space.spaceList}
                    onChange={(e) => {
                        pageData.setPageData("")
                        space.selectSpace(e)
                    }}
                />
            </div>
            <div className={style.actionElement}>
                <ButtonComp
                    icon={ICONS.plus}
                    tooltipText={"Create space"}
                    onClick={() => {
                        modal.open(modalCreateContent)
                    }}
                />
            </div>
            {!!space.selectedSpace &&
                <div className={style.actionElement}>
                    <ButtonComp
                        icon={ICONS.options}
                        tooltipText={"Edit space"}
                        onClick={() => {modal.open(modalEditContent)}}
                    />
                </div>}
            {pageData.page.title && !!space.selectedSpace &&
                <div className={style.headerName}>
                    <InvisibleInputText
                        defaultText={pageData.page.title}
                        tooltipText={"header"}
                        acceptChanges={(newTitle) => {
                            pageData.page.title = newTitle
                            pageData.updatePage(pageData.page)
                        }}
                    />
                </div>
            }
        </div>
        <div className={style.infoPageContent}>
            {!!space.selectedSpace && <InfoSideMenu
                currentSpace={space.selectedSpace}
                generateHistoryPathByPageId={infoData.generateHistoryPathByPageId}
                sideBarWidth={sidebarWidth}
                setSideBarWidth={setSidebarWidth}
            />}
            {!!space.selectedSpace && <InfoContent
                sideBarWidth={sidebarWidth}
            />}
        </div>
    </div>
}