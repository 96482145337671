import style from "./CashWrapper.module.css"
import {CashChartDataProvider} from "../lib/hooks/UseCash";
import {CashPage} from "./CashPage";

export const CashWrapper = () => {
    return <CashChartDataProvider>
        <div className={style.wrapper}>
            <CashPage/>
        </div>
    </CashChartDataProvider>
}