import {useState} from "react";
import {restoreCaretPosition} from "../lib/CaretPosition";
import style from "./Buttons.module.css";
import {InputTextComp} from "../../InputText/InputTextComp";
import {ButtonComp} from "../../Button/ButtonComp";
import {useModal} from "../../../hooks/useModal";

export const LinkModal = ({syncText, savedSelection}) => {
    const {modal} = useModal()
    const [linkName, setLinkName] = useState('')
    const [linkUrl, setLinkUrl] = useState('')

    const insertLink = () => {
        restoreCaretPosition(savedSelection)

        const linkHTML = `<a href="${linkUrl}" title="${linkName}" target="_blank">${linkName}</a>`;
        document.execCommand("insertHTML", false, linkHTML);

        modal.close()
    }
    return <div className={style.modal}>
        <InputTextComp
            title={"Enter name"}
            type={"text"}
            defaultText={linkName}
            acceptChanges={setLinkName}
            autoFocus={true}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    insertLink()
                }
            }}
        />
        <InputTextComp
            title={"Enter URL"}
            type={"text"}
            defaultText={linkUrl}
            acceptChanges={setLinkUrl}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    insertLink()
                }
            }}
        />
        <ButtonComp
            text={"Accept"}
            onClick={() => {
                insertLink()
                syncText()
            }}
        />
    </div>
}
