import style from "./RawButton.module.css"
import {Tooltip} from "react-tooltip";
import {useId} from "react";

export const RawButton = ({
                              content,
                              tooltipText,
                              onClick,
                              minWidth = null,
}) => {

    const customStyle = !!minWidth ? {minWidth: minWidth} : {}
    const id = useId()

    return <div data-tooltip-id={id} className={style.wrapper} style={customStyle}>
        <div className={style.contentContainer} onClick={onClick}>
            {content}
        </div>
        {<Tooltip
            style={{zIndex:5}}
            place={"bottom"}
            content={tooltipText}
            id={id}
            delayShow={500}
        />}
    </div>
}