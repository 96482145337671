import {sendDeleteMsg, sendGetMsg, sendPostMsg, sendPutMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";

export const getCategoriesData = (setCategoriesData) => {
    sendGetMsg(URLS.COOKING_CATEGORY,
        {},
        setCategoriesData
    )
}

export const getReceiptData = (setReceiptData) => {
    sendGetMsg(URLS.COOKING_RECEIPT,
        {},
        setReceiptData
    )
}

export const updateReceiptRecord = (newReceipt, successHandler) => {
    sendPutMsg(URLS.COOKING_RECEIPT,
        newReceipt,
        successHandler
    )
}

export const deleteReceiptRecord = (id, refreshData) => {
    sendDeleteMsg(URLS.COOKING_RECEIPT + "/" + id,
        null,
        refreshData
    )
}

export const getDictionaryUnit = (setDictionaryUnit) => {
    sendGetMsg(URLS.DICTIONARY_UNIT,
        {},
        setDictionaryUnit
    )
}

export const createReceipt = (name, description, resultValue, resultUnit, isPublic, categoryId, file, groupName, refreshData) => {
    const formData = new FormData()
    formData.append('file', file)

    sendPostMsg(URLS.FILES,
        formData,
        (response) => {
            sendPostMsg(URLS.COOKING_RECEIPT,
                {
                    "name": name,
                    "description": description,
                    "imageUrl": response.url,
                    "resultValue": resultValue,
                    "resultUnit": resultUnit,
                    "isPublic": isPublic,
                    "categoryId": categoryId,
                    "groupName": groupName,
                },
                refreshData
            )
        }
    )
}

export const loadFile = (setDataFile, image) => {
    sendGetMsg(URLS.FILES + "/" + image,
        {},
        setDataFile
    )
}