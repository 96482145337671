import {getElementsAtEvent, Line} from "react-chartjs-2";
import React, {useRef} from "react";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import style from "./CashPage.module.css"
import {AddLine} from "./parts/AddLine/AddLine";
import {ViewTransaction} from "./parts/TransactionLine/ViewTransaction";
import {useCash} from "../lib/hooks/UseCash";
import {GetDateWithGmt, GetMillisWithGmt} from "../../../shared/lib/DateHelper";
import {Calendar} from "./parts/Calendar/Calendar";
import {CashStatistics} from "./parts/CashStatistics/CashStatistics";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const CashPage = () => {

    const chartRef = useRef()

    const {setDate, chartData, date} = useCash()

    const data = chartData ? chartData.data : []
    const labels = data.map((item) => GetDateWithGmt(item.day).toLocaleDateString())

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const todayMillis = GetMillisWithGmt(today)

    const valuesBeforeToday = data.map((e) => {
        const date = new Date(e.day)
        date.setHours(0, 0, 0, 0)
        return GetMillisWithGmt(date) > todayMillis ? null : e["balance"]
    })

    const valuesAfterToday = data.map((e) => {
        const date = new Date(e.day)
        date.setHours(0, 0, 0, 0)
        return GetMillisWithGmt(date) >= todayMillis ? e["balance"] : null
    })

    const diagramData = {
        labels: labels,
        datasets: [
            {
                label: 'Done',
                data: valuesBeforeToday,
                borderColor: '#4343d1',
                fill: false,
            },
            {
                label: 'Planed',
                data: valuesAfterToday,
                fill: false,
            },
        ],
    }

    const options = {
        plugins: {
            legend: {
                position: 'right',
            },
        },
        layout: {
            padding: {
                top: 30,
                left: 100,
            },
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7
                }
            }
        },
    }

    const handlePointClick = (event) => {
        const elements = getElementsAtEvent(chartRef.current, event)

        if(elements.length > 0) {
            const index = elements[0].index

            setDate(GetDateWithGmt(data[index].day))

        }
    }

    // const todayLine = {
    //     afterDatasetsDraw: (chart) => {
    //         const {ctx, chartArea: {top, bottom}, scales: {x}} = chart
    //
    //         const indexToday = diagramData.labels.indexOf(new Date().toLocaleDateString());
    //         if (indexToday !== -1) {
    //             ctx.save()
    //             ctx.beginPath()
    //             ctx.lineWidth = 3
    //             ctx.strokeStyle = 'red'
    //             ctx.moveTo(x.getPixelForValue(indexToday), top) // -1
    //             ctx.lineTo(x.getPixelForValue(indexToday), bottom)
    //             ctx.stroke()
    //             ctx.restore()
    //
    //             ctx.fillText('today', x.getPixelForValue(indexToday) - 10, top - 10);
    //         }
    //     },
    // }

    return <div className={style.wrapper}>
        <div className={style.chartBox}>
            <Calendar
                date={date}
                setDate={setDate}
            />
            {chartData && <Line
                data={diagramData}
                options={options}
                // plugins={[todayLine]}
                type={"line"}
                onClick={handlePointClick}
                ref={chartRef}
            />}
            <CashStatistics/>
        </div>
        <AddLine/>
        <ViewTransaction
            transactionList={chartData && chartData.data.filter(e => new Date(e.day).toLocaleDateString() === new Date(date).toLocaleDateString())}
        />
    </div>
}