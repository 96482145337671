import style from "./icons.module.css"
import React from "react";

export const ICONS = {
    typeH1: <img className={style.image} src={"/img/type-h1.svg"} alt={"alt"}/>,
    typeH2: <img className={style.image} src={"/img/type-h2.svg"} alt={"alt"}/>,
    typeH3: <img className={style.image} src={"/img/type-h3.svg"} alt={"alt"}/>,
    typeBold: <img className={style.image} src={"/img/type-bold.svg"} alt={"alt"}/>,
    typeItalic: <img className={style.image} src={"/img/type-italic.svg"} alt={"alt"}/>,
    typeUnderline: <img className={style.image} src={"/img/type-underline.svg"} alt={"alt"}/>,
    textLeft: <img className={style.image} src={"/img/text-left.svg"} alt={"alt"}/>,
    textRight: <img className={style.image} src={"/img/text-right.svg"} alt={"alt"}/>,
    textCenter: <img className={style.image} src={"/img/text-center.svg"} alt={"alt"}/>,
    typeNormal: <img className={style.image} src={"/img/type-normal.svg"} alt={"alt"}/>,
    listOl: <img className={style.image} src={"/img/list-ol.svg"} alt={"alt"}/>,
    listUl: <img className={style.image} src={"/img/list-ul.svg"} alt={"alt"}/>,
    link: <img className={style.image} src={"/img/link.svg"} alt={"alt"}/>,
    codeBlock: <img className={style.image} src="/img/code-block.svg" alt={"alt"}/>,
    textColor: <img src={"/img/text-color.svg"} alt={"alt"}/>,
    underscore: <img src={"/img/underscore.svg"} alt={"alt"}/>,
    eraser: <img className={style.image} src={"/img/eraser.svg"} alt={"alt"}/>,
    text: <img className={style.image} src={"/img/text.svg"} alt={"alt"}/>,

    tableColumnInsertRight: <img className={style.image} src={"/img/insertColumn.svg"} alt={"alt"}/>,
    tableColumnInsertLeft: <img className={style.image} src={"/img/insertColumn.svg"} alt={"alt"} style={{transform:"rotate(180deg)"}}/>,
    tableColumnInsertUp: <img className={style.image} src={"/img/insertColumn.svg"} alt={"alt"} style={{transform:"rotate(-90deg)"}}/>,
    tableColumnInsertDown: <img className={style.image} src={"/img/insertColumn.svg"} alt={"alt"} style={{transform:"rotate(90deg)"}}/>,
    tableDeleteColumn: <img className={style.image} src={"/img/deleteColumn.svg"} alt={"alt"}/>,
    tableDeleteRow: <img className={style.image} src={"/img/deleteColumn.svg"} alt={"alt"} style={{transform:"rotate(-90deg)"}}/>,
    table: <img className={style.image} src={"/img/table.svg"} alt={"alt"}/>,

    emptyMessage: <img className={style.image} src={"/img/empty-message.svg"} alt={"alt"}/>,
    filledMessage: <img className={style.image} src={"/img/filled-mesage.svg"} alt={"alt"}/>,

    arrowUp: <img className={style.image} src={"/img/arrow-up.svg"} alt={"alt"}/>,
    arrowDown: <img className={style.image} src={"/img/arrow-down.svg"} alt={"alt"}/>,

    littleArrow: <img src={"/img/little-arrow.svg"} alt={"alt"}/>,
    rotateLittleArrow: <img src={"/img/little-arrow.svg"} alt={"alt"} style={{transform:"rotate(-180deg)"}}/>,

    account: <img className={style.image} src={"/img/account.svg"} alt={"alt"}/>,
    cross: <img src={"/img/cross.svg"} alt={"alt"}/>,
    delete: <img className={style.image} src={"/img/delete.svg"} alt={"alt"}/>,
    eye: <img className={style.image} src={"/img/eye.svg"} alt={"alt"}/>,
    eyeClosed: <img className={style.image} src={"/img/eye-closed.svg"} alt={"alt"}/>,
    eyeOff: <img className={style.image} src={"/img/eye-off.svg"} alt={"alt"}/>,
    invite: <img className={style.image} src={"/img/invite.svg"} alt={"alt"}/>,
    loading: <img className={style.image} src={"/img/loading.svg"} alt={"alt"}/>,
    lockClose: <img className={style.image} src={"/img/lock-close.svg"} alt={"alt"}/>,
    lockOpen: <img className={style.image} src={"/img/lock-open.svg"} alt={"alt"}/>,
    minusCircle: <img  src={"/img/minusCircle.svg"} alt={"alt"}/>,
    plusCircle: <img src={"/img/plusCircle.svg"} alt={"alt"}/>,
    refresh: <img className={style.image} src={"/img/refresh.svg"} alt={"alt"}/>,
    options: <img className={style.image} src={"/img/options.svg"} alt={"alt"}/>,
    plus: <img className={style.image} src={"/img/plus.svg"} alt={"alt"}/>,
    pencil: <img className={style.image} src={"/img/pencil.svg"} alt={"alt"}/>,
    dotMenu:  <img src={"/img/dot-menu.svg"} alt={"alt"}/>,
}