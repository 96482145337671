import toast from "react-hot-toast";
import {sendDeleteMsg, sendGetMsg, sendPostMsg, sendPutMsg} from "../../../shared/api/SendMsg";
import {URLS} from "../../../shared/const/Backend";

export function getPage(spaceId, pageId, setPage) {
    sendGetMsg(
        URLS.INFO_PAGE,
        {
            "spaceId": spaceId,
            "pageId": pageId
        },
        setPage
    )
}

export function savePage(newPage, setPage) {
    sendPutMsg(URLS.INFO_PAGE,
        newPage,
        setPage,
    )
}
export function getSpacesList(isPrivate = true, successHandler) {
    sendGetMsg(URLS.INFO_SPACE,
        {"isPrivate" : isPrivate},
        successHandler,
        (msg) => {toast.error(msg)}
    )
}
export function createSpace(title, access = true, successHandler) {
    sendPostMsg(URLS.INFO_SPACE,
        {
            "title" : title,
            "isPrivate" : access
        },
        successHandler,
        (msg) => {toast.error(msg)}
    )
}
export function updateSpace(obj, successHandler) {
    sendPutMsg(URLS.INFO_SPACE,
        obj,
        successHandler,
        (msg) => {toast.error(msg)}
    )
}
export function deleteSpace(id, successHandler) {
    sendDeleteMsg(URLS.INFO_SPACE,
        {"id": id},
        successHandler,
        (msg) => {toast.error(msg)}
    )
}

export function getPageList(spaceId, setPageList) {
    sendGetMsg(
        URLS.INFO_PAGE + "/list",
        {"spaceId" : spaceId},
        (e) => {
            setPageList([{
                id: null,
                title: "root",
                position: 0,
                spaceId: spaceId,
                children: e
            }])
        },
        (msg) => {toast.error(msg)}
    )
}

export function createPageRequest(spaceId, title, parentId, invokeRefreshPageList) {
    if (!title || title === "") return;
    sendPostMsg(URLS.INFO_PAGE,
        {"spaceId": spaceId,
            "title": title,
            "parentId": parentId
        },
        invokeRefreshPageList
    )
}

export const editPageRequest = (id, title, spaceId, refreshData) => {
    sendPutMsg(URLS.INFO_PAGE,
        {
            "id": id,
            "title": title,
            "spaceId": spaceId,
        },
        refreshData
    )
}

export function deletePage(spaceId, pageId, invokeRefreshPageList) {
    sendDeleteMsg(URLS.INFO_PAGE,
        {"spaceId": spaceId,
            "id": pageId
        },
        invokeRefreshPageList,
        (msg) => {toast.error(msg)}
    )
}

export function changePosition(spaceId, pageId, newPosition, invokeRefreshPageList) {
    sendPutMsg(URLS.INFO_PAGE + "/list",
        {"spaceId": spaceId,
            "id": pageId,
            "position": newPosition
        },
        invokeRefreshPageList
    )
}