import {Categories} from "./parts/Categories/Categories";
import style from "./CookingPage.module.css"
import React from "react";
import {Nutrition} from "./parts/Nutrition/Nutrition";
import {useCooking} from "../lib/hooks/UseCooking";

export const CookingPage = () => {
    const {selectedCategory} = useCooking()

    return <div className={style.wrapper}>
        {selectedCategory === null || selectedCategory === undefined
            ? <Categories/>
            : <Nutrition/>
        }
    </div>
}