import style from "./Button.module.css";
import {RawButton} from "../RawButton/RawButton";

export const ButtonComp = ({
    text,
    onClick,
    icon,
    tooltipText,
    minWidth = null,
    isActive = false
}) => {
    return <RawButton
        content={
            <div className={`${style.button} ${isActive ? style.activeButton : style.defaultButton}`}>
                {icon}
                {text}
            </div>
        }
        onClick={onClick}
        tooltipText={tooltipText}
        minWidth={minWidth}
    />
}