import style from "./CookingGroupItems.module.css"
import {useState} from "react";
import {CookingReceiptItem} from "../CookingReceiptItem/CookingReceiptItem";
import {ICONS} from "../../../../../shared/ui/img/icons/icons";

export const CookingGroupItems = ({group, setSelectedReceipt}) => {
    const [selectedGroup, setSelectedGroup] = useState(null)

    const handleGroupClick = () => {
        if (selectedGroup && selectedGroup.id === group.id) {
            setSelectedGroup(null)
        } else {
            setSelectedGroup(group)
        }
    }

    return <div className={style.wrapper}>
        <div className={style.groupContainer}>
            <div className={style.groupContent} onClick={handleGroupClick}>
                <div className={style.groupName}>
                    {group.name}
                </div>
                <div className={style.element}>
                    {selectedGroup && selectedGroup.id === group.id ? ICONS.arrowUp : ICONS.arrowDown}
                </div>
            </div>
        </div>
        <div className={style.groupReceiptItem}>
            {selectedGroup && selectedGroup.obj.map((e) =>
                <div key={e.id}>
                    <CookingReceiptItem
                        setSelectedReceipt={setSelectedReceipt}
                        receipt={e}
                    />
                </div>
            )}
        </div>
    </div>
}