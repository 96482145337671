import {createContext, useContext, useEffect, useState} from "react";
import {addRecord, deleteRecord, getChartData, updateRecord} from "../../api/Request";
import {getFirstDayOfMonth, getFirstDayOfNextMonth, GetMillisWithGmt} from "../../../../shared/lib/DateHelper";

const CashDataContext = createContext(null)

export const useCash = () => {
    return useContext(CashDataContext)
}

export const CashChartDataProvider = ({children}) => {
    const [date, setDate] = useState(new Date())
    const [chartData, setChartData] = useState(null)

    const refreshData = () => {
        getChartData(getFirstDayOfMonth(date), getFirstDayOfNextMonth(date), setChartData)
    };

    useEffect(refreshData, [date])

    const addTransaction = (value, product, comment, completed, clearFields, isPositive) => {
        addRecord(GetMillisWithGmt(date), value, product, comment, completed, isPositive, () => {
            clearFields()
            refreshData()
        })
    }

    const getDataByDay = (day) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), day)
        const currentMillis = GetMillisWithGmt(newDate)
        const data = chartData ? chartData.data : []
        return data.filter((e) => e.day === currentMillis);
    }

    const updateTransaction = (newTransaction) => {
        updateRecord(newTransaction, () => {
            refreshData()
        })
    }

    const deleteTransaction = (id) => {
        deleteRecord(id, refreshData)
    }

    const transaction = {
        getDataByDay: getDataByDay,
        setDate: setDate,
        chartData: chartData,
        date: date,
        addTransaction: addTransaction,
        deleteTransaction: deleteTransaction,
        updateTransaction: updateTransaction,
    }

    return <CashDataContext.Provider value={transaction}>{children}</CashDataContext.Provider>;
}