import style from "./CashStatistics.module.css"
import {useCash} from "../../../lib/hooks/UseCash";
export const CashStatistics = () => {
    const {date, getDataByDay} = useCash()

    const filteredData = getDataByDay(date.getDate())

    return <div className={style.wrapper}>
        <div className={style.statistic}>
            <div>{date.toLocaleDateString()}</div>
            <div style={{color: "green"}}>income: {filteredData && filteredData.length > 0 ? filteredData[0]["income"] : 0}</div>
            <div style={{color: "red"}}>expense: {filteredData && filteredData.length > 0 ? filteredData[0]["expense"] : 0}</div>
            <div>sum: {filteredData && filteredData.length > 0 ? filteredData[0]["sum"] : 0}</div>
            <div style={{color: "#4343d1"}}>balance: {filteredData && filteredData.length > 0 ? filteredData[0]["balance"] : 0}</div>
        </div>
    </div>
}